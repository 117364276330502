import React from "react";
import {useAuth0} from "@auth0/auth0-react";


const LoginButton = () => {
    const {loginWithRedirect, logout} = useAuth0();

    return <>
        <h1>You Are Now Logged Out</h1>
        <button onClick={() => loginWithRedirect()}>Log In</button>
        <button onClick={() => logout({returnTo: window.location.origin + "/loggedout"})}>
            Log Out
        </button>
    </>

};

export default LoginButton;